// 问题反馈
// sle 2021年5月31日10:57:02
<template>
  <div class="box flex-row justify-center">
    <div class="main" :style="{ minHeight: minHeight + 'px' }">
      <PageHead title="问题反馈" />
      <router-link to="/UserManagement/MyQuestions/WorkOrder">
        <a-button class="work-order">查看工单记录</a-button>
      </router-link>
      <div class="form">
        <a-spin :spinning="spinning">
          <a-form
            labelAlign="left"
            :model="formState"
            ref="formRef"
            :rules="rules"
            autocomplete="off"
            :labelCol="{ span: 3 }"
            :wrapperCol="{ span: 20 }"
          >
            <a-form-item label="标题" required name="orderTitle">
              <a-input
                :maxlength="30"
                v-model:value="formState.orderTitle"
                placeholder="请输入标题"
              />
            </a-form-item>
            <a-form-item label="邮箱" required name="email">
              <a-input
                :maxlength="60"
                v-model:value="formState.email"
                placeholder="请输入邮箱"
              />
            </a-form-item>
            <a-form-item label="手机号" required name="phone">
              <a-input
                :maxlength="11"
                v-model:value="formState.phone"
                placeholder="请输入电话"
              />
            </a-form-item>
            <a-form-item label="工单类型" name="orderType">
              <a-radio-group v-model:value="formState.orderType">
                <a-radio
                  v-for="r in orderTypeList"
                  :key="r.linkKey"
                  :value="r.linkKey"
                  >{{ r.name }}
                </a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="详细描述" required name="orderDetails">
              <a-textarea
                :maxlength="500"
                v-model:value="formState.orderDetails"
                class="detail"
              />
            </a-form-item>
            <a-form-item class="select_file" label="选择文件" name="filesName">
              <input
                type="file"
                style="display: none"
                ref="file"
                multiple
                @change="change"
              />
              <div class="files">
                <div class="select" @click="selectFile">
                  <div>
                    <img class="img" src="@/assets/tianjia_icon.png" alt="" />
                  </div>
                  <span style="color: #134ccf">请选择文件点击上传</span>
                </div>
                <div class="fileList flex-column">
                  <span
                    class="fileItem"
                    v-for="(r, index) in files"
                    :key="r.name + r.size"
                    :value="formState.filesName"
                  >
                    {{ r.name }}
                    <CloseCircleOutlined
                      @click="deleteFilelItem(index)"
                      class="deleteIcon"
                    />
                  </span>
                </div>
              </div>
              <div class="extension">
                支持扩展名：.rar / .zip / .doc / .docx / .pdf / .jpg / .png ...
              </div>
              <span v-if="error" class="error">请选择文件</span>
            </a-form-item>
            <a-form-item style="margin-top: 20px">
              <a-button type="primary" @click="onSubmit">提交</a-button>
              <a-button style="margin-left: 10px" @click="resetForm"
                >重置</a-button
              >
            </a-form-item>
          </a-form>
        </a-spin>
      </div>
      <a-modal
        v-model:visible="visible"
        ok-text="确认"
        :footer="false"
        cancel-text="取消"
        :maskClosable="false"
        :width="300"
        @cancel="continueNextOne"
      >
        <div
          class="flex-column justify-center align-center successWindows"
          style="height: 350px"
        >
          <CheckCircleOutlined class="successIcon" />
          <div class="successText">反馈成功</div>
          <div>工单号：{{ orderCode }}</div>
          <div class="button flex-row justify-around">
            <a-button @click="continueNextOne">继续</a-button>
            <a-button type="primary" @click="goBack">返回</a-button>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import PageHead from '@/components/PageHead'
import api from '@/api/API'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    PageHead,
    CheckCircleOutlined,
    CloseCircleOutlined,
  },
  data () {
    return {
      error: false,
      minHeight: '400',
      spinning: false,
      visible: false,
      formState: {
        orderTitle: '',
        email: undefined,
        phone: undefined,
        orderType: 0,
        orderDetails: '',
        filesName: '',
      }, // 表单内容
      rules: {
        orderTitle: [
          {
            required: true,
            message: '请输入标题',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 30,
            message: '长度请控制在30以内',
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: '请输入邮箱',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 60,
            message: '长度请控制在60以内',
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur',
          },
          {
            min: 11,
            max: 11,
            message: '请输入正确的11位手机号',
            trigger: 'blur',
          },
        ],
        orderDetails: [
          {
            required: true,
            message: '请输入详情描述',
            trigger: 'blur',
          },
        ],
        filesName: [
          {
            required: true,
            message: '请上传文件',
            trigger: 'blur',
          },
        ],
      }, // 验证规则
      orderTypeList: [], // 工单类型
      orderCode: null, // 工单号
      files: [],
    }
  },
  // 页面加载事件
  created () {
    this.minHeight = innerHeight - 190
    this.getOrdeTypeSelectList()
  },
  // 方法
  methods: {
    // 文件input部分被调用
    change (e) {
      this.files = Array.from(e.target.files)
      this.files.forEach(element => {
        this.formState.filesName = element.name
      })
    },
    // 选择文件
    selectFile () {
      this.$refs.file.dispatchEvent(new MouseEvent('click'))
    },
    // 删除
    deleteFilelItem (e) {
      const list = this.files
      list.splice(e, 1)
      this.files = list
      if (this.files.length === 0) {
        this.formState.filesName = ''
      }
    },
    // 获取岗位列表
    getOrdeTypeSelectList () {
      api
        .get('/api/app/sys-problem-order/sys-problem-order-type-select-list')
        .then(({ data }) => {
          this.orderTypeList = data
        })
        .catch(err => {
          // this.$message.error('获取工单类型状态失败')
          console.log(err)
        })
    },
    // 重置
    resetForm () {
      this.$refs.formRef.resetFields()
      this.obj = {}
      this.files = []
    },
    // 提交
    onSubmit () {
      this.$refs.formRef
        .validate()
        .then(() => {
          this.spinning = true
          const formState = this.formState
          const files = this.files
          const formData = new FormData()
          for (const key in formState) {
            if (Object.hasOwnProperty.call(formState, key)) {
              const element = formState[key]
              formData.append(key, element)
            }
          }
          for (const file of files) {
            formData.append('files', file, file.name)
          }

          api
            .post('/SysProblemOrderFormDataCreate', formData, {
              headers: {
                'Content-Type': 'multipart/form-data; boundary=something',
              },
            })
            .then(({ data }) => {
              this.$message.success('操作成功')
              this.spinning = false
              this.visible = true
              this.orderCode = data
            })
            .catch(err => {
              this.spinning = false
              // this.$message.error('操作失败')
              console.log(err)
            })
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    // 返回
    goBack () {
      this.$router.go(-1)
      this.visible = false
    },
    // 继续下一个工单
    continueNextOne () {
      this.orderCode = null
      this.visible = false
      this.resetForm()
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";

.box {
  width: 100%;
  .main {
    margin-top: 24px;
    background: #fff;
    width: 82%;
    position: relative;
    .form {
      padding: 30px 100px 0 100px;
      .detail {
        height: 120px;
        line-height: 30px;
      }
      .select_file {
        position: relative;
        // margin-left: 10px;
      }
      // .i::after {
      //   position: relative;
      //   top: 2%;
      //   left: -16%;
      //   display: inline-block;
      //   margin-right: 4px;
      //   color: #ff4d4f;
      //   font-size: 14px;
      //   font-family: SimSun, sans-serif;
      //   line-height: 1;
      //   content: "*";
      // }
      .error {
        color: #ff4d4f;
      }
      .fileList {
        margin-left: 20px;
        .fileItem {
          margin: 5px;
        }
        .deleteIcon {
          margin-left: 10px;
          color: red;
        }
      }
      .files {
        padding: 40px 0;
        text-align: center;
        border: 1px dashed #ccc;
        .select {
          cursor: pointer;
        }
        .img {
          width: 20px;
          height: 20px;
        }
      }
      .files:hover {
        border: 1px dashed #1680f4;
      }
      .extension {
        color: #aaa;
      }
    }
  }
}

.work-order {
  position: absolute;
  right: 6%;
  top: 14px;
}
.successWindows {
  .successIcon {
    margin: 10px 0;
    color: #45cb77;
    font-size: 80px;
  }
  .successText {
    margin: 15px 0;
    font-size: 18px;
  }
  .button {
    margin-top: 30px;
    width: 80%;
  }
}
</style>
